<template>
  <Modal
    as-form
    :position="ModalPosition.CENTER"
    :shape="ModalShape.ROUNDED"
    :size="ModalSize.XL"
    :title="$t('common.edit')"
    @submit="save"
  >
    <template #activator="{ activatorProps }">
      <Btn
        v-bind="activatorProps"
        :variant="BtnVariant.OUTLINED"
        :color="BtnColor.PRIMARY"
        :disabled="disabled"
        aria-label="Open edit files"
        class="lg:w-25"
      >
        {{ $t('common.edit') }}
      </Btn>
    </template>
    <template #content>
      <div
        v-if="updatedFiles.length > 1 && isFileEditable"
        class="min-h-14 mt-8 flex rounded-lg overflow-hidden"
      >
        <div
          class="bg-primary text-[white] w-14 flex justify-center items-center"
        >
          <Icon :size="IconSize.LG" :icon="InfoSvg" />
        </div>
        <div class="w-full pl-4 flex items-center relative">
          {{ $t('social-post-files-modal.reorder-info') }}
          <div
            class="bg-primary opacity-10 w-full h-full absolute left-0 top-0"
          ></div>
        </div>
      </div>
      <SocialFileList
        v-model="updatedFiles"
        :no-caption="noCaption"
        :allow-videos="allowVideos"
        :is-file-editable="isFileEditable"
      />
    </template>
    <template #actions="{ closeModal }">
      <div class="flex justify-between py-5 px-4 sm:px-12 border-t">
        <Btn :size="BtnSize.LG" @click="back(closeModal)">
          {{ $t('common.cancel') }}
        </Btn>
        <Btn
          :color="BtnColor.PRIMARY"
          :variant="BtnVariant.FILLED"
          :size="BtnSize.LG"
          :disabled="!hasUnsavedChanges"
          type="submit"
        >
          {{ $t('common.done') }}
        </Btn>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import {
  Modal,
  ModalPosition,
  ModalShape,
  ModalSize,
} from '~/components/modals/modal';
import { computed, ref, watch } from 'vue';
import isEqual from 'lodash/isEqual';
import { Btn, BtnColor, BtnSize, BtnVariant } from '~/components/btn';
import { SocialFileList } from '~/pages/my-posts/_components/post-modal-content/_components/media-manager/_components/social-post-files-modal/_comonents';
import { ContentSocialFileDto } from '~/api/contents';
import cloneDeep from 'lodash/cloneDeep';
import { Icon, IconSize } from '~/components/icon';
import InfoSvg from '~/assets/icons/info.svg?component';

type Props = {
  noCaption?: boolean;
  allowVideos?: boolean;
  disabled?: boolean;
  isFileEditable?: boolean;
};

withDefaults(defineProps<Props>(), {
  isFileEditable: true,
});
const media = defineModel<ContentSocialFileDto[]>({ required: true });
const updatedFiles = ref<ContentSocialFileDto[]>([]);

watch(
  () => media.value,
  (value) => {
    updatedFiles.value = cloneDeep(value);
  },
  { immediate: true, deep: true },
);

const hasUnsavedChanges = computed(() => {
  return !isEqual(media.value, updatedFiles.value);
});

const save = () => {
  media.value = updatedFiles.value.map((file) => ({
    ...file,
    caption: updatedFiles.value.length === 1 ? null : file.caption,
  }));
};

const back = (close: () => void) => {
  updatedFiles.value = cloneDeep(media.value);

  close();
};
</script>
